import { Elm } from '../src/Main.elm';

const apiUrl = "api.stumped.cc";
const storageKey = "token";

const flags = {
  token: localStorage.getItem(storageKey),
  apiUrl: apiUrl
};

const app = Elm.Main.init({flags: flags});

// LOCALSTORAGE

// Handle storing/deleting token in localStorage
app.ports.e2j_StoreToken.subscribe(storeToken);

// Notify Elm if another tab updates localStorage
window.addEventListener("storage", notifyElmAboutLocalStorageUpdate, false);

function storeToken(token) {
  if (token === null) {
    localStorage.removeItem(storageKey);
  }
  else {
    localStorage.setItem(storageKey, token);
  }

  setTimeout(function() {app.ports.j2e_TokenStorageUpdated.send(token);}, 0);
}

function notifyElmAboutLocalStorageUpdate(event) {
  if (event.storageArea === localStorage && event.key === storageKey) {
    app.ports.j2e_TokenStorageUpdated.send(event.newValue);
  }
}

// WEBSOCKETS

import * as AbsintheSocket from "@absinthe/socket";
import { Socket as PhoenixSocket } from "phoenix";

let absintheSocket = null;
let notifiers = [];

app.ports.e2j_ConnectSocket.subscribe(connectSocket);

function connectSocket(token) {
  // Disconnect and remove any previous sockets
  if (absintheSocket !== null) {
    absintheSocket.phoenixSocket.disconnect();
    absintheSocket = null;
  }

  // Create new socket
  absintheSocket = AbsintheSocket.create(
    new PhoenixSocket(`ws://${apiUrl}/socket`, {params: {token: token}})
  );

  // Listen to GraphQL requests from Elm app
  app.ports.e2j_SendSocketRequest.subscribe(createSubscriptions);

  function createSubscriptions(subscription) {
    // Remove existing notifiers
    notifiers.map(notifier => AbsintheSocket.cancel(absintheSocket, notifier));

    // Create new notifiers for each subscription sent
    notifiers = [subscription].map(operation =>
      AbsintheSocket.send(absintheSocket, {
        operation,
        variables: {}
      })
    );

    function onStart(data) {
      console.log(">>> Start", JSON.stringify(data));
      app.ports.j2e_SocketConnected.send(null);
    }

    function onAbort(data) {
      console.log(">>> Abort", JSON.stringify(data));
    }

    function onCancel(data) {
      console.log(">>> Cancel", JSON.stringify(data));
    }

    function onError(data) {
      console.log(">>> Error", JSON.stringify(data));
      app.ports.j2e_SocketReconnectStarted.send(null);
    }

    function onResult(res) {
      console.log(">>> Result", JSON.stringify(res));
      app.ports.j2e_SocketDataReceived.send(res);
    }

    notifiers.map(notifier =>
      AbsintheSocket.observe(absintheSocket, notifier, {
        onAbort,
        onError,
        onCancel,
        onStart,
        onResult
      })
    );
  }
}
